#cta {
	display: block;
	position: relative;
	top: 40vh;
	margin: 0 auto;
	padding: 20px 40px;
	border: 0;
	background: hsl(200,85%,60%);
	color: white;
}

.form {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	box-sizing: border-box;
	background: hsl(200,70%,90%);
	box-shadow: 0 5px 20px rgba(0,0,0,0.4);
	padding: 20px 50px;
	border: 20vw solid rgba(0,0,0,0.5);
	border-bottom: 80vh solid rgba(0,0,0,0.52);
	transition: 0.2s ease;
	z-index: -1;
	opacity: 0;

} 

body.form-active form {
	opacity: 1;
	z-index: 10;
	border-radius: "40px";
}

.name2, .message2, input[type="submit"] {
	display: block;
	box-sizing: border-box;
	border: 0;
	margin: 10px 0;
	padding: 20px 40px;
	text-align: center;
	background: hsl(0,0%,98%);
	width: 100%;
	color: hsl(200, 85%, 20%);
}

.pay, .cancel {
	border: 0;
	padding: 15px 20px;
	text-transform: uppercase;
	display: inline-block;
	margin: 10px;
	color: black;
    
} .pay {
	width: 40%;
	
} .cancel {
	width: 30%;
	
}
.animate-charcter
{
   text-transform: uppercase;
   
  background-image: linear-gradient(
    -225deg,
    #125447 0%,
    #08b3c9 29%,
    #072c82 67%,
    #200675 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


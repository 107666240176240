

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');

/* Apply the Poppins font to the whole page */
body {
  font-family: 'Poppins', sans-serif;
} 
.special{
	font-family: "Playwrite CU", cursive;
}



:root {
	
	--red: #da2c4d;
	--yellow: #f8ab37;
	--green: #1be770;
    --purple:#5f0469;
	--white: #ffffff;
	--grey-light: #f2f7f9;
	--grey: #ecedf3;
	--black: #080808;
	--black-blue: #1f2029;
	--black-blue-light: #353746;
	--black-blue-light-2: #404255;
	--black-blue-light-3: #4b4d64;
	--black-light: #424455;
}












/* #Progress
================================================== */

.progress-wrap {
	position: fixed;
	right: 50px;
	bottom: 50px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset  0 0 0 2px rgba(255,255,255,0.2);
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap::after {
	position: absolute;
	font-family: 'unicons';
	content: '\e84b';
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	color: var(--red);
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: none !important;
	z-index: 1;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::after {
	opacity: 0;
}
.progress-wrap::before {
	position: absolute;
	font-family: 'unicons';
	content: '\e84b';
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	opacity: 0;
	background-image: linear-gradient(298deg, var(--red), var(--yellow));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: none !important;
	z-index: 2;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::before {
	opacity: 1;
}
.progress-wrap svg path { 
	fill: none; 
}
.progress-wrap svg.progress-circle path {
	stroke: var(--purple);
	stroke-width: 4;
	box-sizing:border-box;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
body.light .progress-wrap {
	box-shadow: inset  0 0 0 2px rgba(0,0,0,0.2);
}
body.light .progress-wrap::after {
	color: var(--black-blue);
}
body.light .progress-wrap svg.progress-circle path {
	stroke: var(--black-blue);
}
.switch,
.circle {
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
} 
.switch {
	width: 80px;
	height: 4px;
	border-radius: 27px;
	background-image: linear-gradient(298deg, var(--red), var(--yellow));
	position: fixed;
	right: 50px;
	top: 75px;
	display: none !important;
	margin: 0 auto;
	text-align: center;
	opacity: 1;
  z-index: 33333;
    transition: all 300ms linear;
}
.circle {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: var(--black-blue-light-3);
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
	animation: border-transform 10s linear infinite alternate forwards;
}
.circle:hover {
	box-shadow: 0 8px 8px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}
.circle:before {
	position: absolute;
	font-family: 'unicons';
	content: '\eac1';
	top: 0;
	left: 0;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	width: 100%;
	height: 40px;
	opacity: 1;
	color: var(--grey);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.circle:after {
	position: absolute;
	font-family: 'unicons';
	content: '\eb8f';
	top: 0;
	left: 0;
	z-index: 2;
	font-size: 20px;
	line-height: 40px;
	text-align: center;
	width: 100%;
	height: 40px;
	color: var(--yellow);
	opacity: 0;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.switched {
}
.switched .circle {
	left: 45px;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
	background: var(--black-blue);
}
.switched .circle:hover {
	box-shadow: 0 8px 8px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}
.switched .circle:before {
	opacity: 0;
}
.switched .circle:after {
	opacity: 1;
}

  /* social button */

  .btn-cssbuttons {
	--btn-color: #0b476c;
	position: relative;
	padding: 16px 32px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 16px;
	line-height: 1;
	color: white;
	background: none;
	border: none;
	outline: none;
	overflow: hidden;
	cursor: pointer;
	filter: drop-shadow(0 2px 8px rgba(39, 94, 254, 0.32));
	transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
   }
   
   .btn-cssbuttons::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: var(--btn-color);
	border-radius: 24px;
	transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
   }
   
   .btn-cssbuttons span,
   .btn-cssbuttons span span {
	display: inline-flex;
	vertical-align: middle;
	transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
   }
   
   .btn-cssbuttons span {
	transition-delay: 0.05s;
   }
   
   .btn-cssbuttons span:first-child {
	padding-right: 7px;
   }
   
   .btn-cssbuttons span span {
	margin-left: 8px;
	transition-delay: 0.1s;
   }
   
   .btn-cssbuttons ul {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	display: flex;
	margin: 0;
	padding: 0;
	list-style-type: none;
	transform: translateY(-50%);
   }
   
   .btn-cssbuttons ul li {
	flex: 1;
   }
   
   .btn-cssbuttons ul li a {
	display: inline-flex;
	vertical-align: middle;
	transform: translateY(55px);
	transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
   }
   
   .btn-cssbuttons ul li a:hover {
	opacity: 0.5;
   }
   
   .btn-cssbuttons:hover::before {
	transform: scale(1.2);
   }
   
   .btn-cssbuttons:hover span,
   .btn-cssbuttons:hover span span {
	transform: translateY(-55px);
   }
   
   .btn-cssbuttons:hover ul li a {
	transform: translateY(0);
   }
   
   .btn-cssbuttons:hover ul li:nth-child(1) a {
	transition-delay: 0.15s;
   }
   
   .btn-cssbuttons:hover ul li:nth-child(2) a {
	transition-delay: 0.2s;
   }
   
   .btn-cssbuttons:hover ul li:nth-child(3) a {
	transition-delay: 0.25s;
   }
